import React from "react"

import OfferCard from "./OfferCard"

import Carousel from "react-bootstrap/Carousel"
import Container from "react-bootstrap/Container"

const OfferCarousel = ({ offers, offerUrl }) => (
  <section className="offers mt-5">
    <Container as="div" className="h2 gradient-text text-uppercase">
      Акції для абонентів
    </Container>
    <Container as={Carousel} indicators={false} className="offers-carousel">
      {offers.map(offer => (
        <Carousel.Item key={offer.id} className="offerSlide">
          <OfferCard
            offerImage={offer.offerImage.fluid}
            offerTitle={offer.title}
            offerSlogan={offer.slogan.childMarkdownRemark.html}
            offerUrl="/promo/"
          />
        </Carousel.Item>
      ))}
    </Container>
  </section>
)

export default OfferCarousel
